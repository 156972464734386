import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import ScrollToTop from 'react-scroll-up';

import { getShortUserInfo } from 'rest/user';
import { setShortUserInfo } from 'action/user';
import { stickersList } from 'rest/stickers';
import { getStickersList } from 'action/stickers';
import { setCommentsEditStatus } from 'action/comments';
import { setRmeId } from 'action/options';
import { setFaqHelpId } from 'action/options';
import { topMenuView } from 'action/ui';
import { clearPosts } from 'action/feed';

import Header from '../header';
import Navigation from '../navigation';

import Notification from '../notification';

import WelcomMessage from '../welcomeMessage';
import Router from './Router';
import BottomMenu from '../bottomMenu';

import { showWelcomeMessage } from 'action/showWelcomeMessage';
import confirmedWelcome from 'rest/confirmedWelcome';

import { withLogOut } from 'feature/auth/model';
import { setNotification } from 'action/notification';
import showNotification from 'rest/showNotification';

import { getFavoriteContactsIdsList } from 'action/contacts';

import Loader from 'components/mainLoader';
import { PushPermission } from 'components/push';
import { CacheModal } from 'components/cacheModal';
import Helmet from 'react-helmet';
import BrowserDetect from 'plugins/browserDetect';

import { EmiliaBanner } from 'emilia/entities/banner';
import { CloseEmiliaBannerButton } from 'emilia/features/showImage';
import { addAplicationPermision, addAplicationsPermision } from 'action/hotLine';
import { checkAplicationsPermission, checkPermission } from 'shared/api/hotLine';
import { getOptionsNull } from 'rest/options';
import { BirthdayCongratulation } from 'widgets/birthdayCongratulation';

BrowserDetect.init();

class MainPage extends Component {
	state = {
		windowWidth: window.innerWidth,
		isError: false,
		errorMessage: '',
		birthdayCongratulation: false,
	};

	componentDidMount() {
		const { getFavoriteContactsIdsList, setCommentsEditStatus, setRmeId, setFaqHelpId } =
			this.props;

		getShortUserInfo(this);
		getFavoriteContactsIdsList();
		stickersList(this);
		setCommentsEditStatus(true);
		setRmeId('RME_USER_ID');
		setFaqHelpId('FAQ_PORTAL_ID');
		window.addEventListener('resize', this.handleResize);
		this.checkPermission();
	}

	closeMessage = async event => {
		event.preventDefault();

		this.props.showWelcomeMessage(false);
		const { responseCode, errorMessage } = await confirmedWelcome();

		if (responseCode === 1) {
			showNotification(this, true, 1, errorMessage);
		}
	};

	redirectToSearchResult = query => {
		this.props.history.push('/search-result/' + query);
	};

	renderWelcomeMessage = () => {
		if (this.props.welcomeMessage) {
			return <WelcomMessage closeMessage={this.closeMessage} />;
		} else {
			return null;
		}
	};

	logOutHandler = async e => {
		e.preventDefault();
		this.props.topMenuView();
		await this.props.logOut();
		this.props.clearPosts();
	};

	topMenuViewHandler = () => {
		this.props.topMenuView();
	};

	handleResize = e => {
		this.setState({
			windowWidth: window.innerWidth,
		});
	};

	static getDerivedStateFromError(error) {
		return { isError: true, errorMessage: error };
	}

	checkPermission = async () => {
		const { data } = await getOptionsNull('HOT_LINE_GROUP_ID');

		if (!data.responseCode) {
			(async () => {
				const { response, responseCode } = await checkPermission(data.response?.value);

				if (!responseCode) this.props.addAplicationPermision(!!response.hasPermission);
			})();

			(async () => {
				const { response, responseCode } = await checkAplicationsPermission(
					data.response?.value
				);

				if (!responseCode) this.props.addAplicationsPermision(!!response.hasPermission);
			})();
		}
	};

	closeCongratulationModal = () => {
		this.setState({
			birthdayCongratulation: false,
		});
	};

	componentDidCatch(error, errorInfo) {
		console.error('componentDidCatch', error, errorInfo);
	}

	render() {
		let { windowWidth, birthdayCongratulation } = this.state;

		if (!this.state.isError) {
			return (
				<>
					<Helmet titleTemplate={`%s | ${process.env.REACT_APP_TITLE}`} />
					{windowWidth < 1425 ? null : (
						<ScrollToTop
							showUnder={700}
							style={{
								zIndex: '3',
								top: '0',
								left: '0',
								width: '90px',
								height: '100%',
							}}
						>
							<div className="arrow-up">
								<svg role="img">
									<use
										xlinkHref={
											process.env.PUBLIC_URL + '/img/sprite.svg#mini-arrow'
										}
									/>
								</svg>
								<span>Наверх</span>
							</div>
						</ScrollToTop>
					)}

					<div className="header-wrapper">
						<Header
							windowWidth={windowWidth}
							history={this.props.history}
							match={this.props.match}
							logOut={this.logOutHandler}
						/>
						{windowWidth >= 992 && <Navigation />}

						{!['YaBrowser', 'Safari'].includes(BrowserDetect.browser) && (
							<PushPermission />
						)}
					</div>

					<CacheModal />

					<Notification />

					{this.props.welcomeMessage === false && !this.props.withoutEmilia && (
						<EmiliaBanner closeComponent={<CloseEmiliaBannerButton />} />
					)}

					{this.renderWelcomeMessage()}

					<Suspense
						fallback={
							<section className="allPageLoader">
								<Loader />
							</section>
						}
					>
						<Router windowWidth={this.state.windowWidth} />
					</Suspense>

					{this.state.windowWidth < 992 && <BottomMenu />}

					{birthdayCongratulation && (
						<BirthdayCongratulation closeModal={this.closeCongratulationModal} />
					)}
				</>
			);
		} else {
			return <div>{this.state.errorMessage.toString()}</div>;
		}
	}
}

const mapStateToProps = state => {
	return {
		welcomeMessage: state.welcomeMessage,
		shortUserInfo: state.shortUserInfo,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showWelcomeMessage: show => {
			dispatch(showWelcomeMessage(show));
		},
		setShortUserInfo: user => {
			dispatch(setShortUserInfo(user));
		},
		getStickersList: list => {
			dispatch(getStickersList(list));
		},
		setCommentsEditStatus: status => {
			dispatch(setCommentsEditStatus(status));
		},
		topMenuView: () => {
			dispatch(topMenuView());
		},
		getFavoriteContactsIdsList: () => {
			dispatch(getFavoriteContactsIdsList());
		},
		clearPosts: () => {
			dispatch(clearPosts());
		},
		setRmeId: name => {
			dispatch(setRmeId(name));
		},
		setFaqHelpId: name => {
			dispatch(setFaqHelpId(name));
		},
		setNotification: ntf => {
			dispatch(setNotification(ntf));
		},
		addAplicationPermision: ntf => {
			dispatch(addAplicationPermision(ntf));
		},
		addAplicationsPermision: ntf => {
			dispatch(addAplicationsPermision(ntf));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
	withLogOut(MainPage)
);
